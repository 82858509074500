import React from "react";
import moment from "moment-timezone";

const CustomDateFormat = ({ date, format }) => {
  return (
    <React.Fragment>
      {date != null || "" ? moment(moment(date)).format(format) : null}
    </React.Fragment>
  );
};
export default CustomDateFormat;
